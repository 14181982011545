import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  HostBinding,
  HostListener, inject,
  Input,
  QueryList
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActiveZoneModule } from '../active-zone';
import { ViewModeDirective } from './view-mode.directive';

@Component({
  selector: 'ts-view-edit-mode-switch',
  standalone: true,
  imports: [CommonModule, ActiveZoneModule],
  templateUrl: './view-edit-mode-switch.component.html',
  styleUrls: ['./view-edit-mode-switch.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ViewEditModeSwitchComponent {
  @HostBinding('class.disabled')
  @Input()
  public disable = false;
  @ContentChildren(ViewModeDirective) views!: QueryList<ViewModeDirective>;
  mode: 'view' | 'edit' = 'view';
  private cdr: ChangeDetectorRef = inject(ChangeDetectorRef);

  get viewModeTpl(): ViewModeDirective {
    return this.views.filter(x => x.tsViewMode === 'view')[0];
  }

  get editModeTpl(): ViewModeDirective {
    return this.views.filter(x => x.tsViewMode === 'edit')[0];
  }

  get currentView() {
    return this.mode === 'view' ? this.viewModeTpl?.tpl : this.editModeTpl?.tpl;
  }

  public onActiveZone(active: boolean) {
    if (!active) {
      this.setViewMode();
    }
  }

  @HostListener('keydown.enter')
  @HostListener('keydown.escape')
  setViewMode() {
    if (this.mode === 'edit') {
      setTimeout(() => {
        this.mode = 'view';
        this.cdr.detectChanges();
      }, 200);
    }
  }

  setEditMode() {
    if (this.mode === 'view' && !this.disable) {
      this.mode = 'edit';
    }
  }
}
