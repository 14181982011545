import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { MatDialog } from "@angular/material/dialog";
import { closeSettingsTableModal, openSettingsTableModal } from "./table-settings.actions";
import { exhaustMap, map } from "rxjs";
import { TableSettingsModalComponent } from "../table-settings-modal.component";

@Injectable()
export class TableSettingsModalEffects {
  public openSettingsTableModal = createEffect(() =>
    this.actions.pipe(
      ofType(openSettingsTableModal),
      exhaustMap(({entity, tableIdentifier}: { entity: string, tableIdentifier?:string }) => {
        const dialogConfig = {
          disableClose: true,
          data: {
            hubEntity: entity,
            tableIdentifier,
            title: 'Настройка таблиц',
          },
          backdropClass: 'ts-modal-backdrop',
          panelClass: ['ts-modal-panel', 'ts-modal-panel-lg'],
          width: '470px',
          id: 'ts-modal',
        };

        const dialogRef = this.dialog.open(
          TableSettingsModalComponent,
          dialogConfig
        );

        return dialogRef.afterClosed();
      }),
      map(() => closeSettingsTableModal())
    )
  );

  constructor(
    private actions: Actions,
    private dialog: MatDialog
  ) {
  }
}
