import { FormControl, FormGroup } from '@angular/forms';
import { TableFilter } from '@topseller/core';

export type IdentityMatcher<I> = (previous: I, next: I) => boolean;

export type StringHandler<T> = (item: T) => string;

export interface TableHeader {
  label?: string;
  key: string;
  type?: string;
  width?: string | number;
  minWidth?: number;
  img?: string;
  isSortable?: boolean;
  isResizable?: boolean;
  isPinned?: boolean; // ячейка заголовка закреплена
  isPinnable?: boolean; // можно ли закрепить/открепить.
  textAlign?: 'left' | 'right' | 'center';
  isSortedBy?: boolean; //отсортирована ли таблица по этому полю
  sortDir?: string; //направление сортировки в случае если отсортировано по этому полю.
  filter?: TableFilter[];
  isDynamic?: boolean;
  isEditable?: boolean;
  group?: string;
}

export enum PeriodType {
  yesterday = 'yesterday',
  today = 'today',
  week = 'week',
  month = 'month',
  custom = 'custom',
}

export type Form<T> = {
  [P in keyof T]: T[P] extends 'object'
    ? FormGroup<Form<T[P]>>
    : FormControl<T[P]>;
};

export interface PeriodDateRange {
  dateFrom: string;
  dateTo: string;
  periodType?: PeriodType;
}

export interface DataListHost<T> {
  readonly identityMatcher?: IdentityMatcher<T>;

  handleOption(option?: T): void;

  updateOpen(open: boolean): void;
}

export function getNativeFocused(documentRef: Document): Element | null {
  if (!documentRef.activeElement?.shadowRoot) {
    return documentRef.activeElement;
  }

  let element = documentRef.activeElement.shadowRoot.activeElement;

  while (element?.shadowRoot) {
    element = element.shadowRoot.activeElement;
  }

  return element;
}

export interface ToggleButtonModel {
  name: string;
  id: any;
  requiredRole?: string | string[];
}

export interface ActionItem {
  title: string;
  titleFn?: () => string;
  action: () => void;
  requiredRole?: string | string[];
}
