import { Observable } from 'rxjs';
import { Paginated } from '@topseller/core';
import { Params } from '@angular/router';

export interface PaginationInstance {
  itemsPerPage: number;
  limit?: number;
  currentPage: number;
  totalItems: number;
}

export interface ListQueryParams {
  search: string;
  page: number;
  limit?: number;
  filter?: Record<string, string | number | boolean>;
  filterString?: string;
  sort?: Array<string>;
  sortName?: string;
  sortDir?: string;
}

export type ListOptions = {
  limit: number;
  offset?: number;
  search?: string;
  filter?: Record<string, any>;
  filterString?: string;
  sort?: Array<string>;
  sortName?: string;
  sortDir?: string;
};

export type ListQueryFn<R> = (options: ListOptions) => Observable<Paginated<R>>;

export type MappingFn<T> = (result: Paginated<T>) => {
  items: T[];
  total: number;
};

// эти ключи в адресной строке мы исключаем из фильтра при преобразовании параметров в строку фильтра
const excludedFilterQueryParamsKeys = ['sortName', 'sortDir', 'page', 'limit']

export function toFilterString(
  filter: Record<string, any> | undefined
): string {
  if (!filter) {
    return '';
  }
  const queryParams: string[] = [];

  for (const key in filter) {
    if (excludedFilterQueryParamsKeys.includes(key)) {
      continue;
    }

    // search явно включаем в фильтр, т.к. это неотъемлемая часть фильтра.
    if (key === 'search') {
      queryParams.push(
        `${key}=${encodeURIComponent(filter[key].toString())}`
      );
      continue;
    }


    if (typeof filter[key] === 'object') {
      for (const subKey in filter[key]) {
        const queryKey = `filter[${key}][${subKey}]`;
        queryParams.push(
          `${queryKey}=${encodeURIComponent(filter[key][subKey].toString())}`
        );
      }
    } else {
      const queryKey = key.startsWith('filter[') ? key : `filter[${key}]`;
      queryParams.push(
        `${queryKey}=${encodeURIComponent(filter[key].toString())}`
      );
    }
  }

  return queryParams.join('&');
}

export function extractFilterFromRoute(params: Params): Record<string, any> {
  const filter: Record<string, any> = {};

for (const key of Object.keys(params)) {
  if (key.startsWith('filter[')) {
    const matches = key.match(/filter\[([^\]]+)\](?:\[([^\]]+)\])?/);
    if (matches) {
      if (matches[2]) {
        if (!filter[matches[1]]) {
          filter[matches[1]] = {};
        }
        filter[matches[1]][matches[2]] = params[key];
      } else {
        filter[matches[1]] = params[key];
      }
    }
  }
  if (key === 'search') {
    filter[key] = params[key];
  }
}
return filter;
}

export interface BatchSelectionConfig {
  allSelected: boolean,
  selectedItemsCount: number;
  selectAllFn: () => void;
  deselectAllFn: () => void;
}
